import React from "react";

export default function Footer(){
    return(
        <>
        <footer id="colophon" className="site-footer container-fluid" style={{backgroundColor:"#1d2024",color:"#ccc", padding:"15px 0"}}>
        
		<div className="site-info">
			<div className=" text-center"  style={{fontSize:"16px",backgroundColor:"#1d2024"}}>
				© 2023 <a target="_blank" rel="designer" href="https://easycodingschool.com/" style={{color:"#ccc",backgroundColor:"#1d2024"}}>Easy Coding School</a></div>
		</div>
	</footer>
        </>
    );
}