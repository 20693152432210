import react from "react";
import PropTypes from "prop-types";
import Logo from "../Easy_Coding_School.png";


export default function Navbar() {
  return (
    <>
        <nav className="navbar navbar-expand-lg container-fluid">
  <div className="container">
    <a className="navbar-brand" href="https://easycodingschool.com/"> <img src={Logo} style={{width:"180px"}}/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav  mb-2">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="https://easycodingschool.com/">HOME</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#"></a>
        </li>
        <li className="nav-item dropdown program">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          PROGRAMMING 
          </a>
          <ul className="dropdown-menu border-0">
            <li><a className="dropdown-item" href="https://easycodingschool.com/category/html/">HTML</a></li>
            <li><a className="dropdown-item" href="https://easycodingschool.com/category/php/">PHP</a></li>
            <li><a className="dropdown-item" href="https://easycodingschool.com/category/javascript/">JavaScript</a></li>
            <li><a className="dropdown-item"href="https://easycodingschool.com/tag/git/">Git</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link " aria-disabled="true" href="https://convertcase.easycodingschool.com/">CONVERT CASE</a>
        </li>
        <li className="nav-item">
          <a className="nav-link " aria-disabled="true" href="https://easycodingschool.com/about-us/">ABOUT US</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" aria-disabled="true" href="https://easycodingschool.com/contact/">CONTACT US</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
    </>
  );
}

Navbar.propTypes = {
  title: PropTypes.string
}
