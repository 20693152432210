import React from "react";

export default function TextExplanation() {
    return (
        <>
            <div className="container">
                <div className="row  box_container ">
                    <div className="col-xl-6 col-md-6 col-sm-10 box_ul_li_style my-2">
                        <div>
                            <h5>Convert To Uppercase</h5>
                            <p>
                                The uppercase transformer has the capability to convert any given text into an all-uppercase format.
                            </p>
                            <p>
                                To utilize this feature, follow these steps:
                            </p>
                            <ul>
                                <li className="List_style">Copy and paste the text you wish to transform into the provided box.
                                    Then, click on the "Convert To Uppercase" tab.<br />
                                    <span><strong>Input:</strong> welcome to easycoding school </span>
                                </li>
                                <li>
                                    This process will result in the entire text being converted to uppercase letters, with existing uppercase letters remaining unaffected.<br />
                                    <span><strong>Output:</strong> WELCOME TO EASYCODING SCHOOL </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-10 box_ul_li_style my-2">
                        <div>
                            <h5>Convert To LowerCase</h5>
                            <p>
                                The lowerCase transformer has the capability to convert any given text into an all-lowerCase format.
                            </p>
                            <p>
                                To utilize this feature, follow these steps:
                            </p>
                            <ul>
                                <li>Copy and paste the text you wish to transform into the provided box.
                                    Then, click on the "Convert To LowerCase" tab.<br />
                                    <span><strong>Input:</strong> WELCOME TO EASYCODING SCHOOL</span>
                                </li>
                                <li>
                                    This process will result in the entire text being converted to lowerCase letters, with existing lowerCase letters remaining unaffected.<br />
                                    <span><strong>Output:</strong> welcome to easycoding school</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row box_container ">
                    <div className="col-xl-6 col-md-6 col-sm-10 box_ul_li_style  my-2">
                        <div>
                            <h5>Title Case</h5>
                            <p>
                                The title Case converter is a simple tool for people who aren't sure how to capitalize words in a title. It makes sure important words are capitalized, while leaving less important words in lowercase. For example, "Title" would be capitalized, but words like "an" would stay lowercase.
                            </p>
                            <p>
                                To utilize this feature, follow these steps:
                            </p>
                            <ul>
                                <li className="List_style">Copy and paste the text you wish to transform into the provided box.
                                    Then, click on the "Title Case" tab.<br />
                                    <span><strong>Input:</strong> welcome to easycoding school</span>
                                </li>
                                <li>
                                    This process will result in the first letter being converted to uppercase, while the remaining letters will remain in lowercase.<br />
                                    <span><strong>Output:</strong>Welcome To Easycoding School</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-10 box_ul_li_style  my-2">
                        <div>
                            <h5>Sentence Case</h5>
                            <p>
                                The sentence case works by capitalizing the very first letter in each sentence, and will then go on to transform the rest of the text into lowercase.Every letter after a full stop will get converted into an upper case letter.
                            </p>
                            <p>
                                To utilize this feature, follow these steps:
                            </p>
                            <ul>
                                <li>Copy and paste the text you wish to transform into the provided box.
                                    Then, click on the "Sentence Case" tab.<br />
                                    <span><strong>Input:</strong> welcome to easycoding school. easycoding school is learning hub of programming.</span>
                                </li>
                                <li>
                                    It works by capitalizing the very first letter in each sentence, and will then go on to transform the rest of the text into lowercase.<br />
                                    <span><strong>Output:</strong> Welcome to easycoding school. Easycoding school is learning hub of programming.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}