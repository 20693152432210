import React, { useState } from "react";

export default function TextForm(props) {
    const handleUpClick = () => {
        // console.log("Upppercase was clicked" +text);
        let newText = text.toUpperCase();
        setText(newText);

    }
    const handleLoClick = () => {
        // console.log("Upppercase was clicked" +text);
        let newText = text.toLowerCase();
        setText(newText);

    }
    const handleonchange = (event) => {
        // console.log("on change");
        setText(event.target.value);
    }
    const handleClearClick = (event) => {
        // console.log("on change");
        setText(event.target.value);
    }
    const handleCopy = () => {
        var text = document.getElementById("mybox");
        text.select();
        navigator.clipboard.writeText(text.value);

    }
    const handleExtraSpaces = () => {
        var newText = text.split(/[ ]+/);
        console.log(newText);
        setText(newText.join(" "))
    }

    const toProperCase = (str) => {
        return str.replace(/\w\S*/g, (word) => {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        });
    };

    const [text, setText] = useState(" ");

    const titleCase = () => {
        var inputValue = text;
        const properCaseValue = toProperCase(inputValue);
        setText(properCaseValue);
    };

    const toSentenceCase = (str) => {
        return str.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, (match) => {
            console.log(match);
            return match.toUpperCase();
        });
    };



    const sentenceCase = () => {
        var newInput = text.toLowerCase();
        console.log(newInput);
        const sentenceCaseText = toSentenceCase(newInput);
        setText(sentenceCaseText);

    };


    return (
        <>
            <div className="container" style={{ Color: props.mode === "dark" ? "white" : "dark" }}>
                <p className="text-center head_styling"><a href="https://easycodingschool.com/" rel="follow" target="_blank" style={{ color: "#cb2229", textDecoration: "none", fontWeight: "700" }}>Easy Coding School</a> is a free online tool to convert the case of any text to  either lower case,<br /> upper case, proper, Remove Extra Space or sentence case.</p>
                <div className="my-3">
                    <textarea id="textarea" className="form-control" style={{ backgroundColor: props.mode === "dark" ? "gray" : "white" }} onChange={handleonchange} value={text} id="mybox" rows="8" placeholder="Enter Your Text Here"></textarea>
                </div>
                <button className="btn_style mx-1 my-1" onClick={handleUpClick}>Convert To Uppercase</button>
                <button className="btn_style mx-1 my-1" onClick={handleLoClick}>Convert To LowerCase</button>
                <button className="btn_style mx-1 my-1" onClick={titleCase}>Title Case</button>
                <button className="btn_style mx-1 my-1" onClick={sentenceCase}>Sentence Case</button>
                <button className="btn_style mx-1 my-1" onClick={handleExtraSpaces}>Remove Extra Spaces</button>
                <button className="btn_style mx-1 my-1" onClick={handleCopy}>Copy Text</button>
                <button className="btn_style mx-1 my-1" onClick={handleClearClick}>Clear Text</button>
            </div>
            <div className="container my-3">
                <h4>Please wait</h4>
                <p>{text.split(" ").length-1} words and {text.length}</p>
                <p>please wait {0.008 * text.split(" ").length} seconds </p>
                
            </div>

        </>

    );
}